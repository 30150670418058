import {
  ref,
  onMounted,
  getCurrentInstance,
  defineComponent
} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import {
  deliveryManifestUseCase
} from '@/domain/usecase'
import DepatureManifest from '@/views/transactions/delivery-item/delivery-manifest/detail/tab-view/DepatureManifest.vue'
import LoadingManifest from '@/views/transactions/delivery-item/delivery-manifest/detail/tab-view/LoadingManifest.vue'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

export default defineComponent({
  components: {
    DepatureManifest,
    LoadingManifest
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $confirm, $toast, $strInd
    } = app!.appContext.config.globalProperties
    const data = ref({
      TransJadwalDetails: []
    })
    const currentTab = ref(0)
    const store = useStore()
    const route = useRoute()
    const id = ref(null) as any

    const getAllData = async () => {
      store.dispatch('showLoading')
      const response = await deliveryManifestUseCase.getOneDetail(id.value, {
        select: '&$select=KodeJadwal,Rit,TotalPendapatan',
        expand: `&$expand=TipeBus($select=Kode),TransJadwalDetails($filter=AgenId eq ${store.getters['appActiveUser/getAgent']?.id};$select=WaktuKeberangkatan)`
      })
      if (!response.error) {
        data.value = response.result
      } else {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeTab = (val: any) => {
      currentTab.value = val
    }

    onMounted(() => {
      id.value = route.params.id
      if (id.value) {
        getAllData()
      }
    })

    return {
      data,
      currentTab,
      getAllData,
      changeTab,
      PermissionEnum,
      checkPermission
    }
  }
})
